class Location {
  constructor () {
    this.id = 0
    this.type = null
    this.name = null
  }

  toString () {
    return `Location{id = ${this.id}, name='${this.name}', type='${this.type}'}`
  }
}
export default Location

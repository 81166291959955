
class DeviantAct {
  constructor () {
    this.id = null
    this.act = null
    this.description = null
  }

  toString () {
    return `DeviantAct{id=${this.id}, act='${this.act}', description='${this.description}'}`
  }
}
export default DeviantAct

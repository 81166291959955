import ApiEndpoint from '../http/EndPoint'
import { axios, handleError, getRequestCancelToken } from '../http/HttpResource'

import AddressService from './AddressService'

let instance = null
const centerUrl = ApiEndpoint.centerUrl
const timeUnitUrl = ApiEndpoint.timeUnitUrl
const statusUrl = ApiEndpoint.statusUrl
const centerTypeUrl = ApiEndpoint.centerTypeUrl

/**
 * Fetches Center object(s) filtered based on specified parameters.
 *
 * @param {Object} reqParam Request parameters.
 * @returns {Promise<Center>} Promise with filtered center/centers.
 */
const fetchWithParams = async reqParam => {
  try {
    let response = null
    response = await axios.get(centerUrl, {
      params: reqParam,
      cancelToken: getRequestCancelToken(response)
    })
    return response.data
  } catch (error) {
    return handleError(error)
  }
}

/**
 * Validates the provided status.
 *
 * @param {*} status
 * @returns {boolean} true or false.
 */
const isValidStatus = status => {
  return (status == null) ? false
    : (!(typeof status === 'string')) ? false
      : ['ACTIVE', 'LOCKED', 'INACTIVE'].includes(status.toUpperCase())
}

/**
 * Validates the provided type.
 *
 * @param {*} type
 * @returns {boolean} true or false.
 */
const isValidType = type => {
  return (type == null) ? false
    : (!(typeof type === 'string')) ? false
      : ['HEADQUARTER', 'REHAB', 'TRANSIT'].includes(type.toUpperCase())
}

/**
 * Call API to update the center object
 *
 * @param {*} center the center object
 * @returns the center object to be updated
 */
const updateCenter = async center => {
  try {
    let updatedCenter = null
    center.address = AddressService.getLastChild(center.address)
    updatedCenter = await axios.put(centerUrl, center, { cancelToken: getRequestCancelToken(updatedCenter) })
    return updatedCenter.data
  } catch (error) {
    return handleError(error)
  }
}
/**
 * call API to create the center object
 *
 * @param {*} center the center object
 * @returns return center object to be created
 */
const createCenter = async center => {
  try {
    let createdCenter = null
    center.address = AddressService.getLastChild(center.address)
    createdCenter = await axios.post(centerUrl, center, { cancelToken: getRequestCancelToken(createdCenter) })
    return createdCenter.data
  } catch (error) {
    return handleError(error)
  }
}

/**
 * This module represents an API singleton repository.
 * It contains code that connects to DMIS Center API endpoints.
 */
class _CenterService {
  /**
   * Constructs a CenterService instance.
   */
  constructor () {
    if (!instance) instance = this
    return instance
  }

  /**
 * Calls API to create or update center object
 *
 * @param {*} centerObj the center object
 * @returns the center object to be created or updated
 * @memberof _CenterService
 */
  async saveCenter (centerObj) {
    let response
    if (centerObj == null) throw new Error('Invalid center object')
    else if (centerObj.id) {
      response = await updateCenter(centerObj)
      return response
    } else {
      response = await createCenter(centerObj)
      return response
    }
  }

  async deleteCenter (id) {
    try {
      let response = null
      response = await axios.delete(`${centerUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls the API to fetch all centers.
   *
   * @returns {Promise<Center>} Promise with all centers.
   */
  async fetchAll (page, size, typeIds) {
    try {
      let centers = null
      centers = await axios.get(centerUrl, {
        params: {
          page: page,
          size: size,
          typeIds: typeIds
        },
        cancelToken: getRequestCancelToken(centers)
      })
      return centers.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllRehabCenters () {
    try {
      let centers = null
      centers = await axios.get(centerUrl, {
        params: {
          type: 'REHABILITATION'
        },
        cancelToken: getRequestCancelToken(centers)
      })
      return centers.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllAnyParams (params) {
    try {
      let centers = null
      centers = await axios.get(centerUrl, {
        params: params,
        cancelToken: getRequestCancelToken(centers)
      })
      return centers.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllList () {
    try {
      let centerObj = null
      centerObj = await axios.get(`${centerUrl}/names`, { cancelToken: getRequestCancelToken(centerObj) })
      return centerObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllRehabCentersList (type) {
    try {
      let centerObj = null
      centerObj = await axios.get(`${centerUrl}/names`, {
        params: { type: type },
        cancelToken: getRequestCancelToken(centerObj)
      })
      return centerObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls the API to retrieve a center object with specified ID.
   *
   * @param {number} id Center ID. Must be greater than 0.
   * @returns {Promise<Center>} Promise with a center whose ID was specified.
   */
  async fetchById (id) {
    if (id == null) throw new Error('ID must not be null.')
    try {
      let center = null
      center = await axios.get(`${centerUrl}/${id}`, { cancelToken: getRequestCancelToken(center) })
      return center.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls the API to fetch either public or private centers based on specified value.
   *
   * @param {boolean} isPublic Must be either true or false.
   * @returns {Promise<Center>} Promise with a list of public or private centers.
   */
  async fetchByCategory (isPublic) {
    if (isPublic == null || !(typeof isPublic === 'boolean')) throw new Error('The parameter must be a boolean.')
    const requestParam = {
      public: isPublic
    }
    return fetchWithParams(requestParam)
  }

  /**
   * Calls the API to fetch centers whose name contains the specified argument.
   *
   * @param {string} centerName Center name. Must not be null/undefined.
   * @returns {Promise<Center>} Promise with a list of centers whose name contains the specified value.
   */
  async fetchByName (centerName) {
    if (centerName == null || !(typeof centerName === 'string')) throw new Error('Invalid search string.')
    const requestParam = {
      name: centerName
    }
    return fetchWithParams(requestParam)
  }

  /**
   * Calls the API to fetch centers with a specified status.
   *
   * @param {string} centerStatus Must be either 'ACTIVE', 'LOCKED', or 'INACTIVE'.
   * @returns {Promise<Center>} Promise including a list of centers with specified status.
   */
  async fetchByStatus (centerStatus) {
    if (!isValidStatus(centerStatus)) throw new Error('Invalid status.')
    const requestParam = {
      name: centerStatus
    }
    return fetchWithParams(requestParam)
  }

  /**
   * Calls the API to fetch centers of specified type.
   *
   * @param {string} centerType Must be either 'HEADQUARTER', 'REHAB', 'TRANSIT'.
   * @returns {Promise<Center>} Promise including a list of centers with specified type.
   */
  async fetchByType (centerType) {
    if (!isValidType(centerType)) throw new Error('Invalid type.')
    const requestParam = {
      type: centerType
    }
    return fetchWithParams(requestParam)
  }

  /**
 * Calls API to fetch list of all TimeUnits
 *
 * @returns list of all TimeUnits
 * @memberof _CenterService
 */
  async fetchAllTimeUnits () {
    try {
      let timeUnitObj = null
      timeUnitObj = await axios.get(timeUnitUrl, { cancelToken: getRequestCancelToken(timeUnitObj) })
      return timeUnitObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch list of all status
 *
 * @returns list of all status
 * @memberof _CenterService
 */
  async fetchAllStatus () {
    try {
      let statusObj = null
      statusObj = await axios.get(statusUrl, { cancelToken: getRequestCancelToken(statusObj) })
      return statusObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch list of all Center Type
 *
 * @returns list of all center type
 * @memberof _CenterService
 */
  async fetchAllCenterType () {
    try {
      let centerTypeObj = null
      centerTypeObj = await axios.get(centerTypeUrl, { cancelToken: getRequestCancelToken(centerTypeObj) })
      return centerTypeObj.data
    } catch (error) {
      return handleError(error)
    }
  }
}

const centerService = new _CenterService()
export default centerService

import Role from './Role'

class User {
  constructor () {
    this.id = null
    this.username = null
    this.password = null
    this.firstName = null
    this.lastName = null
    this.email = null
    this.phone = null
    this.position = null
    this.center = null
    this.roles = []
  }

  addRole (role) {
    if (role == null || !(role instanceof Role)) throw new Error('Invalid role.')
    this.privileges.push(role)
  }

  toString () {
    return `User{id=${this.id}, username='${this.username}', password='${this.password}',
      firstName='${this.firstName}', lastName='${this.lastName}', email='${this.email}',
      phone='${this.phone}', position='${this.position}', center=${this.center}, roles=${this.roles}}`
  }
}

export default User

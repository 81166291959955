import Location from './Location'

class Address {
  constructor () {
    this.province = new Location()
    this.district = new Location()
    this.sector = new Location()
    this.cell = new Location()
    this.village = new Location()
  }

  toString () {
    return `Address{province=${this.province}, district=${this.district}, 
        sector=${this.sector}, cell=${this.cell}, village=${this.village}}`
  }
}

export default Address

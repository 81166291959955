import Endpoint from '../http/EndPoint'
import qs from 'qs'
import {
  axios,
  handleError,
  getRequestCancelToken
} from '../http/HttpResource'

let instance = null

class _ReportsService {
  constructor () {
    if (!instance) instance = this
    return instance
  }

  async getReport (payload) {
    try {
      let response = null
      response = await axios.get(Endpoint.reportUrl + '/' + payload.reportId, {
        params: payload.params,
        paramsSerializer: params => {
          return qs.stringify(params)
        },
        cancelToken: getRequestCancelToken(response)
      })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getReportList () {
    try {
      let response = null
      response = await axios.get(Endpoint.reportUrl, {
        cancelToken: getRequestCancelToken(response)
      })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }
}
const reportService = new _ReportsService()
export default reportService

import Vue from 'vue'
import Router from 'vue-router'
// import NProgress from 'nprogress'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: () => import('@/views/Login')
    },
    {
      name: 'Token Request',
      path: '/token-request',
      component: () => import('@/views/TokenRequest')
    },
    {
      name: 'Password Reset',
      path: '/password-reset',
      component: () => import('@/views/PasswordReset')
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Assessment',
          path: 'rehabilitation/assessment',
          component: () => import('@/views/pages/Assessments')
        }
      ]
    },

    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Intake Enrolment',
          path: 'rehabilitation/intake-enrolment',
          component: () => import('@/views/pages/Intake')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Trade Enrolment',
          path: 'rehabilitation/training-enrolment',
          component: () => import('@/views/pages/Training')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Trade Class',
          path: 'rehabilitation/training-class',
          component: () => import('@/views/pages/Class')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'BehaviorAssessment',
          path: 'rehabilitation/behaviorAssessment',
          component: () => import('@/views/pages/BehaviorAssessment')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Psychotherapy',
          path: 'rehabilitation/psychotherapy',
          component: () => import('@/views/pages/Psychotherapy')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Graduation',
          path: 'graduation/manage-graduation',
          component: () => import('@/views/pages/Graduation')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Request Graduation Approval',
          path: 'graduation/request-graduation-approval',
          component: () => import('@/views/pages/RequestGraduationApproval')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Approve Graduation List',
          path: 'graduation/approve-graduation-list',
          component: () => import('@/views/pages/ApproveGraduationList')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Release Graduates',
          path: 'graduation/release-graduates',
          component: () => import('@/views/pages/ReleaseGraduates')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Organizations',
          path: 'post-graduation/organizations',
          component: () => import('@/views/pages/Organizations')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Graduates',
          path: 'post-graduation/graduates',
          component: () => import('@/views/pages/Graduates')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Approval',
          path: 'graduation/single-graduation',
          component: () => import('@/views/pages/SingleGraduation')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Organization',
          path: 'organization/single-organization',
          component: () => import('@/views/pages/SingleOrganization')
        }
      ]
    },
    // {
    //   path: '/',
    //   component: () => import('@/views/Index'),
    //   children: [
    //     {
    //       name: 'Psycho-Social',
    //       path: 'psycho-social/behavior assessment',
    //       component: () => import('@/views/pages/BehaviorAssessment')
    //     }
    //   ]
    // },
    // {
    //   path: '/',
    //   component: () => import('@/views/Index'),
    //   children: [
    //     {
    //       name: 'Psycho-Social',
    //       path: 'psycho-social/psychotherapy',
    //       component: () => import('@/views/pages/Psychotherapy')
    //     }
    //   ]
    // },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Reports',
          path: 'reports/list',
          component: () => import('@/views/pages/ReportsList')
        },
        {
          name: 'specificReports',
          path: 'reports/:reportId',
          component: () => import('@/views/pages/SpecifiedReport')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Prepare List',
          path: 'graduation/prepare-list',
          component: () => import('@/views/pages/PrepareList')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Delinquent',
          path: 'delinquents/delinquents',
          component: () => import('@/views/pages/ViewDelinquents')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Deviant Acts',
          path: 'delinquents/deviant-acts',
          component: () => import('@/views/pages/DeviantActs')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Apprehension Items',
          path: 'delinquents/apprehension-items',
          component: () => import('@/views/pages/ApprehensionItems')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Identification Document',
          path: 'delinquents/identification-doc',
          component: () => import('@/views/pages/IdentificationDoc')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Discharge Delinquent',
          path: 'delinquents/discharge-delinquent',
          component: () => import('@/views/pages/DischargeDelinquent')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'General Screening',
          path: 'screening/general-screening',
          component: () => import('@/views/pages/GenScreening')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Medical Screening',
          path: 'screening/medical-screening',
          component: () => import('@/views/pages/MedicalScreening')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Approval',
          path: 'screening/approval',
          component: () => import('@/views/pages/Approval')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Request For Approval',
          path: 'screening/request-for-approval',
          component: () => import('@/views/pages/RequestForApproval')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Transfer',
          path: 'movement/transfer',
          component: () => import('@/views/pages/Transfer')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Receive',
          path: 'movement/receive',
          component: () => import('@/views/pages/Receive')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Release',
          path: 'movement/release',
          component: () => import('@/views/pages/Release')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          name: 'Change Password',
          path: 'changepassword',
          component: () => import('@/views/ChangePassword')
        },
        {
          name: 'User Profile',
          path: 'userprofile',
          component: () => import('@/views/UserProfile')
        },
        {
          name: 'Delinquent Profile',
          path: 'delinquent-profile',
          component: () => import('@/views/pages/DelinquentProfile')
        }
      ]
    },
    {
      path: '/administration',
      component: () => import('@/views/Index'),
      children: [
        // Dashboard
        {
          name: 'Manage Userss',
          path: '',
          component: () => import('@/views/dashboard/Dashboard')
        },
        {
          name: 'Manage Users',
          path: 'users',
          component: () => import('@/views/administration/Users')
        },
        {
          name: 'Manage Roles',
          path: 'roles',
          component: () => import('@/views/administration/Roles')
        },
        {
          name: 'Manage Centers',
          path: 'centers',
          component: () => import('@/views/administration/Centers')
        },
        {
          name: 'Manage Positions',
          path: 'positions',
          component: () => import('@/views/administration/Positions')
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error')
        }
      ]
    }
  ]
})
// router.beforeResolve((to, from, next) => {
//   if (to.Dashboard) {
//     NProgress.start()
//   }
//   next()
// })

// router.afterEach(() => {
//   NProgress.done()
// })

export default router


class Items {
  constructor () {
    this.id = null
    this.name = null
  }

  toString () {
    return `Items{id=${this.id}, name='${this.name}'}`
  }
}
export default Items

import ApiEndpoint from '../http/EndPoint'
import { axios, handleError, getRequestCancelToken } from '../http/HttpResource'
// import Address from '../models/Address'

let instance = null
const addressUrl = ApiEndpoint.addressUrl

/**
 * Finds the lowest location in specified address,
 * keeps it and deletes all other locations from the object.
 *
 * @param {Address} address Must not be null.
 * @returns {Address} Address with a single location.
 */
const getAddressWithLowestLocationOnly = address => {
  if (address.village.id !== 0) {
    delete address.cell
    delete address.sector
    delete address.district
    delete address.province
  } else if (address.cell.id !== 0) {
    delete address.village
    delete address.sector
    delete address.district
    delete address.province
  } else if (address.sector.id !== 0) {
    delete address.village
    delete address.cell
    delete address.district
    delete address.province
  } else if (address.district.id !== 0) {
    delete address.village
    delete address.cell
    delete address.sector
    delete address.province
  } else if (address.province.id !== 0) {
    delete address.village
    delete address.cell
    delete address.sector
    delete address.district
  } else {
    address = null
  }
  return address
}

class _AddressService {
  /**
   * Constructs a AddressService instance.
   */
  constructor () {
    if (!instance) instance = this
    return instance
  }

  /**
   * Calls the API to retrieve addresses with at least one location name containing the specified string.
   *
   * @param {string} name Name of the location or part of it.
   * @returns {Promise<Address>} Promise with a list of address objects.
   */
  async fetchAddresses (name) {
    if (name == null || !(typeof name === 'string')) throw new Error('Invalid search string for locations.')

    try {
      let addresses = null
      addresses = await axios.get(`${addressUrl}/${name}`, { cancelToken: getRequestCancelToken(addresses) })
      return addresses.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Leave the lowest not-null location in specified address object and deletes other locations.
   *
   * @param {Address} address Must not be null.
   * @returns {Address} Address object with its lowest location only or null if no such location found.
   */
  getLastChild (address) {
    if (address == null) return null
    // if (!(address instanceof Address)) throw new Error('Invalid address object.')
    return getAddressWithLowestLocationOnly(address)
  }
}

const addressService = new _AddressService()
export default addressService

import Address from './Address'
import DocumentType from './DocumentType'
import Items from './Items'
import DeviantAct from './DeviantAct'

class Apprehension {
  constructor () {
    this.id = null
    this.apprehensionDate = null
    this.apprehensionWay = null
    this.apprehenderName = null
    this.apprehenderPhone = null
    this.apprehenderFunction = null
    this.delinquentResidence = new Address()
    this.apprehensionPlace = new Address()
    this.documentTypes = []
    this.items = []
    this.deviantActs = []
  }

  addDocumentTypes (documentType) {
    if (documentType == null || !(documentType instanceof DocumentType)) throw new Error('Invalid Document Type.')
    this.documentTypes.push(documentType)
  }

  addItems (item) {
    if (item == null || !(item instanceof Items)) throw new Error('Invalid  Item.')
    this.items.push(item)
  }

  addDeviantActs (deviantAct) {
    if (deviantAct == null || !(deviantAct instanceof DeviantAct)) throw new Error('Invalid Deviant Act.')
    this.deviantActs.push(deviantAct)
  }

  toString () {
    return `Apprehension{id='${this.id}', apprehensionDate='${this.apprehensionDate}', howApprehended='${this.howApprehended}',
    apprehenderName='${this.apprehenderName}', apprehenderPhone='${this.apprehenderPhone}', apprehenderFunction='${this.apprehenderFunction}',
    delinquentId=${this.delinquentId}, placeOfResidence=${this.placeOfResidence}, apprehensionPlace=${this.apprehensionPlace},
    documentTypes=${this.documentTypes}, items=${this.items}, deviantActs=${this.deviantActs}}`
  }
}

export default Apprehension

/* eslint-disable no-console */

import $psychotherapyService from '@/services/PsychotherapyService'

export const state = () => ({
  progressNotes: [],
  assessmentQuestions: [],
  assessmentPsychotherapyQuestions: []
})

export const getters = {
  progressNotes: (state) => state.progressNotes,
  progressNotesByDelinquent: (state) => id => {
    return state.progressNotes.filter(el => el.delinquent === id)
  },
  progressNotesByDate: (state) => (startDate, endDate) => {
    return state.progressNotes.filter(el => new Date(el.therapy_date) >= new Date(startDate) && new Date(el.therapy_date) <= new Date(endDate))
  },
  assessmentQuestions: (state) => state.assessmentQuestions,
  assessmentPsychotherapyQuestions: (state) => state.assessmentPsychotherapyQuestions,
  assessmentPsychotherapyAnswersByQuestion: (state) => precedence => {
    return state.assessmentPsychotherapyQuestions.find(el => el.precedence === precedence).answerOptions
  },
  assessmentPsychotherapyQuestionsByPrecedence: (state) => precedence => {
    return state.assessmentPsychotherapyQuestions.find(el => el.precedence === precedence)
  }
}
export const mutations = {
  readProgressNotes: (state, payload) => {
    state.progressNotes = payload
  },
  addProgressNotes: (state, payload) => {
    state.progressNotes.push(payload)
  },
  updateProgressNotes: (state, payload) => {
    const index = state.progressNotes.findIndex(el => el.id === payload.id)
    state.progressNotes.splice(index, 1, payload)
  },
  removeProgressNote: (state, id) => {
    const index = state.progressNotes.indexOf(el => el.id === id)
    state.progressNotes.splice(index, 1)
  },
  getAssessmentQs: (state, payload) => {
    state.assessmentQuestions = payload
  },
  getAssessmentPsychotherapyQuestions: (state, payload) => {
    state.assessmentPsychotherapyQuestions = payload
  }
}
export const actions = {
  readProgressNotes: ({ commit }, payload) => {
    commit('readProgressNotes', payload)
  },
  addProgressNotes: ({ commit }, payload) => {
    commit('addProgressNotes', payload)
  },
  updateProgressNotes: ({ commit }, payload) => {
    commit('updateProgressNotes', payload)
  },
  removeProgressNote: ({ commit }, id) => {
    commit('removeProgressNote', id)
  },
  getAssessmentQs ({ commit }) {
    $psychotherapyService.getAssessmentQs().then(res => {
      commit('getAssessmentQs', res)
    })
  },
  getAssessmentPsychotherapyQuestions ({ commit }) {
    $psychotherapyService.getAssessmentPsychotherapyQuestions().then(res => {
      commit('getAssessmentPsychotherapyQuestions', res)
    }).catch(err => console.log(err))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

// import $psychotherapyService from '@/services/PsychotherapyService'

export const state = () => ({
  delinquents: [],
  rehabilitatedDelinquents: []
})

export const getters = {
  delinquents: (state) => state.delinquents,
  totalDelinquent: (state) => state.delinquents.length,
  rehabilitatedDelinquents: (state) => state.rehabilitatedDelinquents,
  rehabilitatedDelinquentsByStatus: (state) => status => {
    return state.rehabilitatedDelinquents.filter(el => el.behaviorAssessmentStatus === status)
  },
  rehabilitatedDelinquentsByName: (state) => name => {
    return state.rehabilitatedDelinquents.filter(el => el.delinquent.firstName.toUpperCase() === name.toUpperCase() || el.delinquent.lastName.toUpperCase() === name.toUpperCase())
  },
  rehabilitatedDelinquentsByNameAndStatus: (state) => (name, status) => {
    return state.rehabilitatedDelinquents.filter(el => (el.delinquent.firstName.toUpperCase() === name.toUpperCase() || el.delinquent.lastName.toUpperCase() === name.toUpperCase()) && el.behaviorAssessmentStatus === status)
  }
}
export const mutations = {
  readDelinquents: (state, payload) => {
    state.delinquents = payload
  },
  readRehabilitatedDelinquents: (state, payload) => {
    state.rehabilitatedDelinquents = payload
  },
  updateRehabilitatedDelinquents: (state, id) => {
    const index = state.rehabilitatedDelinquents.findIndex(el => el.id === id)
    if (index === -1) return
    if (state.rehabilitatedDelinquents[index].behaviorAssessmentStatus === 'PENDING') {
      state.rehabilitatedDelinquents[index].behaviorAssessmentStatus = 'COMPLETED'
    } else state.rehabilitatedDelinquents[index].behaviorAssessmentStatus = 'PENDING'
    // console.log(id)
  },
  dischargeDelinquent: (state, id) => {
    const index = state.delinquents.findIndex(el => el.id === id)
    if (index > -1) state.delinquents.splice(index, 1)
  }
}
export const actions = {
  readDelinquents: ({
    commit
  }, payload) => {
    commit('readDelinquents', payload)
  },
  readRehabilitatedDelinquents: ({
    commit
  }, payload) => {
    commit('readRehabilitatedDelinquents', payload)
  },
  updateRehabilitatedDelinquents: ({
    commit
  }, id) => {
    commit('updateRehabilitatedDelinquents', id)
  },
  dischargeDelinquents: ({ commit }, id) => {
    commit('dischargeDelinquent', id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import Privilege from './Privilege'

class Role {
  constructor () {
    this.id = null
    this.name = null
    this.description = null
    this.privileges = []
  }

  addPrivilege (privilege) {
    if (privilege == null || !(privilege instanceof Privilege)) throw new Error('Invalid privilege.')
    this.privileges.push(privilege)
  }

  toString () {
    return `Role{id = ${this.id}, name = '${this.name}', description = '${this.description}',
      privileges = ${this.privileges}}`
  }
}

export default Role


class Screening {
  constructor () {
    this.id = null
    this.meetingDate = null
    this.decision = null
    this.decisionReason = null
    this.transferCenter = null
    this.committeeMembers = null
    this.delinquentPointOfView = null
    this.guardianPointOfView = null
    this.approvalStatus = null
    this.approver = null
    this.approvalDate = null
  }

  toString () {
    return `Screening{id=${this.id}, meetingDate='${this.meetingDate}', Decision='${this.Decision}',
    decisionReason='${this.decisionReason}', transferCenter='${this.transferCenter}', committeeMembers='${this.committeeMembers}',
    delinquentPointOfView='${this.delinquentPointOfView}', guardianPointOfView='${this.guardianPointOfView}', approvalStatus='${this.approvalStatus}',
    approver='${this.approver}', approvalDate='${this.approvalDate}'}`
  }
}

export default Screening

import Address from './Address'

class Center {
  constructor () {
    this.id = null
    this.name = null
    this.email = null
    this.phone = null
    this.type = null
    this.address = new Address()
    this.public = true
    this.capacity = null
    this.stayTime = null
    this.stayTimeMeasure = null
  }

  get isPublic () {
    return this.public
  }

  set isPublic (isPublic) {
    this.public = (isPublic === 'true')
  }

  toString () {
    return `Center{id=${this.id}, name='${this.name}', email='${this.email}',
        phone='${this.phone}', type='${this.type}', isPublic=${this.public},
        address=${this.address}, capacity='${this.capacity}',staytime='${this.stayTime}',
        stayTimeMeasure=${this.stayTimeMeasure} }`
  }
}

export default Center

import ApiEndpoint from '../http/EndPoint'
import {
  axios,
  handleError,
  getRequestCancelToken
} from '../http/HttpResource'

let instance = null
const baseAPI = 'http://localhost:4000'
const delinquentNumberByGenderUrl = ApiEndpoint.delinquentNumberByGenderUrl
const deliquentNumberByDistrictUrl = ApiEndpoint.delinquentNumberByDistrictUrl
const deliquentNumberByDecisionUrl = ApiEndpoint.delinquentNumberByDecisionUrl
const deliquentRecidivistUrl = ApiEndpoint.delinquentRecidivistUrl
const deliquentToRehabCenterUrl = ApiEndpoint.delinquentToRehabCenterUrl

class _DashboardService {
  constructor () {
    if (!instance) instance = this
    return instance
  }

  async getDelinquentNumberByGender (state, periodDate, exitDatePeriod) {
    try {
      let response = null
      response = await axios.get(delinquentNumberByGenderUrl, {
        params: {
          states: state,
          recordDates: periodDate,
          exitDates: exitDatePeriod
        },
        cancelToken: getRequestCancelToken(response)
      })
      // console.log('Check this Data')
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getDelinquentRecidivist (state, periodDate, exitDatePeriod) {
    try {
      let response = null
      response = await axios.get(deliquentRecidivistUrl, {
        params: {
          trStates: state,
          startDate: periodDate,
          endDate: exitDatePeriod
        },
        cancelToken: getRequestCancelToken(response)
      })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getDelinquentToRehab (state, startDate, endDate) {
    try {
      let response = null
      response = await axios.get(deliquentToRehabCenterUrl, {
        params: {
          trStates: state,
          startDate: startDate,
          endDate: endDate
        },
        cancelToken: getRequestCancelToken(response)
      })
      // console.log('To Rehab' + JSON.stringify(response))
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getAllDelinquentCards () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/delinquentCards', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log('First Data: ' + response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getCurrentDelinquents () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/currently', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getDelinquentByTimeRC () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/overals', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getScreenings () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/screenings', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getGenderCategorieValues () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/genderCategorieValues', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getDecisions () {
    try {
      let response = null
      // response = await axios.get(baseAPI + '/decisions', {
      response = await axios.get(deliquentNumberByDecisionUrl, {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data.decisions
    } catch (error) {
      return handleError(error)
    }
  }

  async getTransfers () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/transfers', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  decisions;
  async getOrgins () {
    try {
      let response = null
      // response = await axios.get(baseAPI + "/orgins", {
      response = await axios.get(deliquentNumberByDistrictUrl, {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log('Hello: ' + JSON.stringify(response.data.origins))
      return response.data.origins
    } catch (error) {
      return handleError(error)
    }
  }

  async getDelinquents () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/delinquents', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getIntakes () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/intakes', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getTraineds () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/traineds', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getGenders () {
    try {
      let response = null
      response = await axios.get(baseAPI + '/genders', {
        cancelToken: getRequestCancelToken(response)
      })
      // console.log(response.data)
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }
}

const dashboardService = new _DashboardService()
export default dashboardService

import axios from 'axios'
import NProgress from 'nprogress'

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, max-age=0'
  },
  withCredentials: true,
  timeout: 30000
})
// before a request is made start the nprogress
axiosInstance.interceptors.request.use(config => {
  NProgress.start()
  return config
}, function (error) {
  NProgress.done()
  return Promise.reject(error)
})

// before a response is returned stop nprogress
axiosInstance.interceptors.response.use(response => {
  NProgress.done()
  return response
}, function (error) {
  NProgress.done()
  return Promise.reject(error)
})

/**
 * Handles errors thrown during API calls
 *
 * @param {Error} error thrown error.
 * @returns {Object} object with status and error message.
 */
const handleError = error => {
  if (error.response) return error.response.data
  else return { status: 'error', message: error.message }
}

/**
 * Creates a cancel token for axios requests and cancels requests after 10secs without a response.
 *
 * @param {*} response declared variable to hold API response.
 * @returns {axios.CancelToken} axios' cancel token.
 */
const getRequestCancelToken = (response) => {
  const source = axios.CancelToken.source()
  response = null
  setTimeout(() => {
    if (response == null) source.cancel('Server not responding. Please contact system administrator.')
  }, 30000)
  return source.token
}

export { axiosInstance as axios, handleError, getRequestCancelToken }

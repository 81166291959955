import ApiEndpoint from '../http/EndPoint'
import { axios, handleError, getRequestCancelToken } from '../http/HttpResource'

let instance = null
const locationUrl = ApiEndpoint.locationUrl

/**
 * Validates the provided type.
 *
 * @param {*} type
 * @returns {boolean} true or false.
 */
const isValidType = type => {
  return (type == null) ? false
    : (!(typeof type === 'string')) ? false
      : (['province', 'district', 'sector', 'cell', 'village'].includes(type.toLowerCase()))
}

/**
 * This module represents an API singleton repository.
 * It contains code that connects to DMIS Location API endpoints.
 */
class _LocationService {
  /**
   * Constructs a CenterService instance.
   */
  constructor () {
    if (!instance) instance = this
    return instance
  }

  /**
   * Calls the API to fetch all locations of a specified type.
   *
   * @param {string} locationType Must be 'province', 'district', 'sector', 'cell', or 'village'.
   * @returns {Promise<Center>} Promise with locations of specified type.
   */
  async fetchByType (locationType) {
    if (!isValidType(locationType)) throw new Error('Invalid type.')

    try {
      let location = null
      location = await axios.get(locationUrl, {
        params: { type: locationType },
        cancelToken: getRequestCancelToken(location)
      })
      return location.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls the API to fetch a location having specified ID.
   *
   * @param {number} id Must be a integer greater than 0.
   * @returns {Promise<Center>} Promise with a location of specified ID.
   */
  async fetchById (id) {
    if (id == null || isNaN(id) || id <= 0) throw new Error('ID must be a number greater than 0.')

    try {
      let location = null
      location = await axios.get(`${locationUrl}/${id}`, { cancelToken: getRequestCancelToken(location) })
      return location.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls the API to fetch a parent location of one having specified ID.
   *
   * @param {number} id Child location ID. Must be a integer greater than 0.
   * @returns {Promise<Center>} Promise with a parent location.
   */
  async fetchParent (id) {
    if (id == null || isNaN(id) || id <= 0) throw new Error('ID must be a number greater than 0.')

    try {
      let location = null
      location = await axios.get(`${locationUrl}/${id}/parent`, { cancelToken: getRequestCancelToken(location) })
      return location.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls the API to fetch direct children locations of one having specified ID.
   *
   * @param {number} id Parent location ID. Must be a integer greater than 0.
   * @returns {Promise<Center>} Promise with a children locations.
   */
  async fetchChildren (id) {
    if (id == null || isNaN(id) || id < 0) throw new Error('ID must be a number greater than 0.')

    try {
      let location = null
      location = await axios.get(`${locationUrl}/${id}/children`, { cancelToken: getRequestCancelToken(location) })
      return location.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllDistricts () {
    try {
      let districtsObj = null
      districtsObj = await axios.get(`${locationUrl}?type=district`, { cancelToken: getRequestCancelToken(districtsObj) })
      return districtsObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllSiblings (address) {
    if (address == null) throw new Error('Address must not be null.')

    /* Assign 10 as an infinite value where there is no ID */
    const provinceId = address.province ? address.province.id : 10
    const districtId = address.district ? address.district.id : 10
    const sectorId = address.sector ? address.sector.id : 10
    const cellId = address.cell ? address.cell.id : 10

    const addresses = await Promise.all([
      this.fetchChildren(provinceId),
      this.fetchChildren(districtId),
      this.fetchChildren(sectorId),
      this.fetchChildren(cellId)
    ])

    return addresses
  }
}

const locationService = new _LocationService()
export default locationService

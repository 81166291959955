<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
/* tbody tr:nth-of-type(even) {
    background-color: rgba(236, 237, 237);
  } */

/* tbody tr:nth-of-type(odd) {
    background-color: rgb(250 ,250, 250);
  } */

.v-data-table-header th {
  /* background-color: rgba(182, 183, 187); */
  /* color: white; */
  font-size: 15px !important;
  font-weight: 900 !important;
}

/* .v-data-footer {
    background-color: rgb(250 ,250, 250);
  } */

/* .theme--light.v-data-table thead tr th {
    color: white;
  } */
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

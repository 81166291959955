import Endpoint from '../http/EndPoint'
import { axios, handleError, getRequestCancelToken } from '../http/HttpResource'

let instance = null

class _PsychotherapyService {
  constructor () {
    if (!instance) instance = this
    return instance
  }

  async getProgressNotes (params) {
    try {
      let response = null
      response = await axios.get(Endpoint.psychotherapyProgressNotesUrl, { params, cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getProgressNoteById ({ params, id }) {
    try {
      let response = null
      response = await axios.get(Endpoint.psychotherapyProgressNotesUrl + '/' + id, { params, cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async addProgressNotes (payload) {
    try {
      let response = null
      response = await axios.post(Endpoint.psychotherapyProgressNotesUrl, payload, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async updateProgressNotes (payload) {
    try {
      let response = null
      response = await axios.put(Endpoint.psychotherapyProgressNotesUrl, payload, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteProgressNotes (id) {
    try {
      let response = null
      response = await axios.delete(Endpoint.psychotherapyProgressNotesUrl + '/' + id, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getAssessmentQs () {
    try {
      let response = null
      response = await axios.get(Endpoint.assessmentQuestions, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getAssessmentQsById (id) {
    try {
      let response = null
      response = await axios.get(Endpoint.behaviorAssessmentResultsUrl + '/' + id, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async saveBehaviorAssessment (payload) {
    try {
      let response = null
      response = await axios.post(Endpoint.behaviorAssessmentResultsUrl, payload, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteBehaviorAssessment (id) {
    try {
      let response = null
      response = await axios.delete(Endpoint.behaviorAssessmentResultsUrl + '/' + id, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getAssessmentPsychotherapyQuestions () {
    try {
      let response = null
      response = await axios.get(Endpoint.assessmentPsychotherapyQuestionsUrl, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }
}

const psychotherapyService = new _PsychotherapyService()
export default psychotherapyService

import ApiEndpoint from '../http/EndPoint'
import { axios, handleError, getRequestCancelToken } from '../http/HttpResource'

let instance = null
const userUrl = ApiEndpoint.userUrl
const positionUrl = ApiEndpoint.positionUrl
const roleUrl = ApiEndpoint.roleUrl
const privilegeUrl = ApiEndpoint.privilegeUrl

/**
 * Calls API to update the position object
 *
 * @param {*} position the position object
 * @returns the updated position object
 */
const updatePosition = async position => {
  try {
    let updatedPosition = null
    updatedPosition = await axios.put(positionUrl, position, { cancelToken: getRequestCancelToken(updatedPosition) })
    return updatedPosition.data
  } catch (error) {
    return handleError(error)
  }
}

/**
 * Calls API to create the Position Object
 *
 * @param {*} position the position Object
 * @returns the created position Objet
 * @memberof _UserService
 */
const createPosition = async position => {
  try {
    let createdPosition = null
    createdPosition = await axios.post(positionUrl, position, { cancelToken: getRequestCancelToken(createdPosition) })
    return createdPosition.data
  } catch (error) {
    return handleError(error)
  }
}

/**
 * Calls API to create role object
 *
 * @param {*} roleObj the role object
 * @returns the role objetc to be created
 * @memberof _UserService
 */
const createRole = async role => {
  try {
    let createRoleObj = null
    createRoleObj = await axios.post(roleUrl, role, { cancelToken: getRequestCancelToken(createRoleObj) })
    return createRoleObj.data
  } catch (error) {
    return handleError(error)
  }
}

/**
*  Calls API to update the role object
*
* @param {*} roleObj the role object
* @returns role object to be updated
* @memberof _UserService
*/
const updateRole = async role => {
  try {
    let updateRoleObj = null
    updateRoleObj = await axios.put(roleUrl, role, { cancelToken: getRequestCancelToken(updateRoleObj) })
    return updateRoleObj.data
  } catch (error) {
    return handleError(error)
  }
}

/**
 * Calls API to create the user object
 *
 * @returns the user object to be created
 * @memberof _UserService
 */
const createUser = async user => {
  try {
    let createUserObj = null
    createUserObj = await axios.post(userUrl, user, { cancelToken: getRequestCancelToken(createUserObj) })
    return createUserObj.data
  } catch (error) {
    return handleError(error)
  }
}

/**
* Calls API to update the user object
*
* @returns the user object to be updated
* @memberof _UserService
*/
const updateUser = async user => {
  try {
    let updateUserObj = null
    updateUserObj = await axios.put(userUrl, user, { cancelToken: getRequestCancelToken(updateUserObj) })
    return updateUserObj.data
  } catch (error) {
    return handleError(error)
  }
}

class _UserService {
  /**
   * Constructs a AddressService instance.
   */
  constructor () {
    if (!instance) instance = this
    return instance
  }

  /**
 * Calls API to fetch the current User
 *
 * @returns the current user
 * @memberof _UserService
 */
  async fetchCurrentUser (expand = null) {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/current`, {
        params: expand ? { expand: 'roles,privileges' } : {},
        cancelToken: getRequestCancelToken(userObj)
      })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch roles of the current user
 *
 * @returns the roles of the current user
 * @memberof _UserService
 */
  async fetchLoggedInUserRoles () {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/current/roles`, { cancelToken: getRequestCancelToken(userObj) })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch Privileges of the current User
 *
 * @returns the privileges of the current user
 * @memberof _UserService
 */
  async fetchLoggedInUserPrivileges () {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/current/privileges`, { cancelToken: getRequestCancelToken(userObj) })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Call API to fetch Center of the current user
 *
 * @returns the center of the current user
 * @memberof _UserService
 */
  async fetchLoggedInUserCenter () {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/current/center`, { cancelToken: getRequestCancelToken(userObj) })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch user of ther specified ID
 *
 * @param {*} id the user ID
 * @returns the user of the specified ID
 * @memberof _UserService
 */
  async fetchSingleUser (id, expand = null) {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/${id}`, {
        params: expand ? { expand: 'roles,privileges' } : {},
        cancelToken: getRequestCancelToken(userObj)
      })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API the fetch list of All Users
 *
 * @returns list of all DMIS users
 * @memberof _UserService
 */
  async fetchAllUsers (page, size, sort) {
    try {
      let userObj = null
      userObj = await axios.get(userUrl, {
        params: {
          page: page,
          size: size,
          sort: 'firstName,desc'
        },
        cancelToken: getRequestCancelToken(userObj)
      })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteUser (id) {
    try {
      let response = null
      response = await axios.delete(`${userUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  async lockUser (id) {
    try {
      let response = null
      response = await axios.put(`${userUrl}/${id}/lock`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch Roles of the Specified User
 *
 * @param {*} id the ID
 * @returns the Roles of the scpecied user ID
 * @memberof _UserService
 */
  async fetchRoles (id) {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/${id}/roles`, { cancelToken: getRequestCancelToken(userObj) })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch  Center of the specified User
 *
 * @param {*} id the ID
 * @returns the center of the specified User
 * @memberof _UserService
 */
  async fetchCenter (id) {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/${id}/center`, { cancelToken: getRequestCancelToken(userObj) })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchCurrentCenter () {
    try {
      let userObj = null
      userObj = await axios.get(`${userUrl}/current/center`, { cancelToken: getRequestCancelToken(userObj) })
      return userObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch list of All Roles
 *
 * @returns list of all roles
 * @memberof _UserService
 */
  async fetchAllRoles (page, size, sort) {
    try {
      let roleObj = null
      roleObj = await axios.get(roleUrl, {
        params: {
          page: page,
          size: size,
          sort: 'name,asc'
        },
        cancelToken: getRequestCancelToken(roleObj)
      })
      return roleObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllRolesList () {
    try {
      let roleObj = null
      roleObj = await axios.get(`${roleUrl}/names`, { cancelToken: getRequestCancelToken(roleObj) })
      return roleObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch list of All Privileges
 *
 * @returns list of all Privileges
 * @memberof _UserService
 */
  async fetchAllPrivileges () {
    try {
      let privilegeObj = null
      privilegeObj = await axios.get(privilegeUrl, { cancelToken: getRequestCancelToken(privilegeObj) })
      return privilegeObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch Privilege of the specified User
 *
 * @param {*} id The User ID
 * @returns privilege of the specified user
 * @memberof _UserService
 */
  async fetchSinglePrivilege (id) {
    try {
      let privilegeObj = null
      privilegeObj = await axios.get(`${privilegeUrl}/${id}`, { cancelToken: getRequestCancelToken(privilegeObj) })
      return privilegeObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch Role of the specified Role
 *
 * @param {*} id the role ID
 * @returns the Role of the Specified role ID
 * @memberof _UserService
 */
  async fetchSingleRole (id) {
    try {
      let roleObj = null
      roleObj = await axios.get(`${roleUrl}/${id}`, {
        params: { timestamp: new Date().getTime() },
        cancelToken: getRequestCancelToken(roleObj)
      })
      return roleObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteRole (id) {
    try {
      let response = null
      response = await axios.delete(`${roleUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
 * Calls API to fetch list of all Positions
 *
 * @returns list of all Positions
 * @memberof _UserService
 */
  async fetchAllPositions (page, size, sort) {
    try {
      let positionObj = null
      positionObj = await axios.get(positionUrl, {
        params: {
          page: page,
          size: size,
          sort: 'name,desc'
        },
        cancelToken: getRequestCancelToken(positionObj)
      })
      return positionObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  async fetchAllPositionsList () {
    try {
      let positionObj = null
      positionObj = await axios.get(`${positionUrl}/names`, { cancelToken: getRequestCancelToken(positionObj) })
      return positionObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls API to create or update the Position Object
   *
   * @param {*} positionObj the position Object
   * @returns the position Objet to be created or updated
   * @memberof _UserService
   */
  async savePosition (positionObj) {
    let response
    if (positionObj == null) throw new Error('Invalid position object')
    else if (positionObj.id) {
      response = await updatePosition(positionObj)
      return response
    } else {
      response = await createPosition(positionObj)
      return response
    }
  }

  async fetchPositionById (id) {
    try {
      let positionObj = null
      positionObj = await axios.get(`${positionUrl}/${id}`, { cancelToken: getRequestCancelToken(positionObj) })
      return positionObj.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Logs current user out.
   *
   * @returns {Promise<Object>} Promise with status or error object.
   */
  async deletePosition (id) {
    try {
      let response = null
      response = await axios.delete(`${positionUrl}/${id}`, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Calls API to create or update the role Object
   *
   * @param {*} roleObj the role Object
   * @returns the role Objet to be created or updated
   * @memberof _UserService
   */
  async saveRole (roleObj) {
    let response
    if (roleObj == null) throw new Error('Invalid role object')
    else if (roleObj.id) {
      response = await updateRole(roleObj)
      return response
    } else {
      response = await createRole(roleObj)
      return response
    }
  }

  /**
   * Calls API to create or update the user Object
   *
   * @param {*} positionObj the user Object
   * @returns the user Objet to be created or updated
   * @memberof _UserService
   */
  async saveUser (userObj) {
    let response
    if (userObj == null) throw new Error('Invalid user object')
    else if (userObj.id) {
      response = await updateUser(userObj)
      return response
    } else {
      response = await createUser(userObj)
      return response
    }
  }

  /**
 * Requests the server to change specified user's existing password to the specified password.
 *
 * @param {String} userId the specified user ID
 * @param {String} oldPassword the specified user old password
 * @param {String} newPassword the new password
 * @returns {Promise<Object>} Promise with status or error object.
 * @memberof _AuthenticationService
 */
  async changePassword (userId, oldPassword, newPassword) {
    const changePasswordObj = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
    try {
      let response = null
      response = await axios.patch(`${userUrl}/${userId}`, changePasswordObj, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }
}
const userService = new _UserService()
export default userService

import ApiEndpoint from '../http/EndPoint'
import { axios, handleError, getRequestCancelToken } from '../http/HttpResource'

let instance = null
const authUrl = ApiEndpoint.authenticationUrl
const userUrl = ApiEndpoint.userUrl

/**
 * This module represents an API singleton repository.
 * It contains code that connects to DMIS authentication API endpoints.
 */
class _AuthenticationService {
  /**
   * Constructs an AuthenticationService instance.
   */
  constructor () {
    if (!instance) instance = this
    return instance
  }

  /**
   * Calls the API to log the user in.
   *
   * @param {string} username Must neither be null nor empty.
   * @param {string} password Must neither be null nor empty.
   *
   * @returns {Promise<Object>} Promise with status or error object.
   */
  async login (username, password) {
    try {
      const credential = { loginId: username, password: password }
      let response = null
      response = await axios.post(authUrl, credential, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Logs current user out.
   *
   * @returns {Promise<Object>} Promise with status or error object.
   */
  async logout () {
    try {
      let response = null
      response = await axios.delete(authUrl, { cancelToken: getRequestCancelToken(response) })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Requests the server to send password reset token.
   *
   * @param {string} username Must neither be null nor empty
   * @param {string} email Must neither be null nor empty
   * @returns {Promise<Object>} Promise with status or error object.
   */
  async requestToken (username, email) {
    try {
      let response = null
      response = await axios.get(`${userUrl}/password-reset-token`, {
        params: {
          username: username,
          email: email
        },
        cancelToken: getRequestCancelToken(response)
      })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  /**
   * Requests the server to reset specified user's existing password to the specified password.
   *
   * @param {string} loginId Must neither be null nor empty
   * @param {string} password Must neither be null nor empty
   * @param {string} token Must neither be null nor empty
   * @returns {Promise<Object>} Promise with status or error object.
   */
  async resetPassword (loginId, password, token) {
    const passwordResetObject = {
      loginId: loginId,
      password: password,
      token: token
    }
    try {
      let response = null
      response = await axios.patch(userUrl, passwordResetObject, {
        cancelToken: getRequestCancelToken(response)
      })
      return response.data
    } catch (error) {
      return handleError(error)
    }
  }

  checkAuthentication (httpStatus, router, store) {
    if (httpStatus.includes(401)) {
      store.commit('SET_AUTHENTICATED', false)
      router.push('/login')
    }
  }
}

const authService = new _AuthenticationService()
export default authService

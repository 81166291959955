import Vue from 'vue'
import Vuex from 'vuex'
import store from './modules/store'
import delinquent from './modules/delinquent'
import psychotherapy from './modules/psychotherapy'
import reports from './modules/reports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    store,
    psychotherapy,
    delinquent,
    reports
  }
})

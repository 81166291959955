import User from '@/models/User'
import Center from '@/models/Center'
import DelinquentService from '@/services/DelinquentService'
import LocationService from '@/services/LocationService'
import router from '@/router/router'

const getDefaultState = () => {
  return {
    snackbar: false,
    color: null,
    text: null,
    timeout: -1,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    isAuthenticated: false,
    currentUser: new User(),
    currentCenter: new Center(),
    search: null,
    showSearch: false,
    searchLabel: null,
    loading: null,
    decisions: [],
    positions: [],
    education: [],
    storeProvinces: [],
    storeProvincesError: null,
    deviantActs: [],
    identificationDocuments: [],
    apprehensionItems: [],
    delinquents: [],
    trainingClass: [],
    delinquentItem: null,
    storeGraduation: null,
    btnControl: false,
    storeOrganization: null
  }
}
const state = getDefaultState()

export const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  SET_SNACKBAR (state, payload) {
    state.snackbar = payload
  },
  SET_COLOR (state, payload) {
    state.color = payload
  },
  SET_TEXT (state, payload) {
    state.text = payload
  },
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
  SET_SCRIM (state, payload) {
    state.barColor = payload
  },
  SET_AUTHENTICATED (state, payload) {
    state.isAuthenticated = payload
  },
  SET_CURRENT_USER (state, payload) {
    state.currentUser = payload
  },
  SET_CURRENT_CENTER (state, payload) {
    state.currentCenter = payload
  },
  SET_SEARCH (state, search) {
    state.search = search
  },
  SET_SHOW_SEARCH (state, payload) {
    state.showSearch = payload
  },
  SET_SEARCH_LABEL (state, payload) {
    state.searchLabel = payload
  },
  START_LOADING (state, payload) {
    state.loading = state.loading++
  },
  FINISH_LOADING (state, payload) {
    state.loading = state.loading--
  },
  SET_DECISIONS (state, payload) {
    state.decisions = payload
  },
  SET_DECISIONS_UNSHIFT: state => state.decisions.unshift(''),
  SET_POSITIONS (state, payload) {
    state.positions = payload
  },
  SET_EDUCATION (state, payload) {
    state.education = payload
  },
  SET_STORE_PROVINCES (state, payload) {
    state.storeProvinces = payload
  },
  SET_STORE_PROVINCES_ERROR (state, payload) {
    state.storeProvincesError = payload
  },
  SET_DEVIANTS_ACTS (state, payload) {
    state.deviantActs = payload
  },
  SET_IDENTIFICATION_DOCUMENTS (state, payload) {
    state.identificationDocuments = payload
  },
  SET_APPREHENSION_ITEMS (state, payload) {
    state.apprehensionItems = payload
  },
  SET_DELINQUENTS (state, payload) {
    state.delinquents = payload
  },
  SET_TRAINING_CLASS (state, payload) {
    state.trainingClass = payload
  },
  SET_DELINQUENT_ITEM (state, payload) {
    state.delinquentItem = payload
  },
  SET_STORE_GRADUATION (state, payload) {
    state.storeGraduation = payload
  },
  SET_BTNCONTROL (state, payload) {
    state.btnControl = payload
  },
  SET_STORE_ORGANIZATION (state, payload) {
    state.storeOrganization = payload
  }
}
export const getters = {
  isAuthenticated: state => state.isAuthenticated,
  currentUser: state => state.currentUser,
  currentCenter: state => state.currentCenter,
  storeOrganization: state => state.storeOrganization
}
export const actions = {
  loadDecisions ({ commit, state }) {
    DelinquentService.fetchDecisions()
      .then(response => {
        if (response.length > 0) commit('SET_DECISIONS', response)
      })
  },
  loadCommitteeMembersPositions ({ commit, state }) {
    DelinquentService.fetchCommitteeMembersPositions()
      .then(response => {
        if (response.length > 0) commit('SET_POSITIONS', response)
      })
  },
  loadEducationLevels ({ commit, state }) {
    DelinquentService.fetchEducationLevels()
      .then(response => {
        if (response.length > 0) commit('SET_EDUCATION', response)
      })
  },
  loadStoreProvinces ({ commit, state }) {
    LocationService.fetchChildren(0)
      .then(response => {
        if (!response.status) {
          commit('SET_STORE_PROVINCES', response)
        } else {
          commit('SET_STORE_PROVINCES_ERROR', response.message)
        }
      })
  },
  loadDeviantActs ({ commit, state }) {
    DelinquentService.fetchAllDeviantActList()
      .then(response => {
        if (!response.status) commit('SET_DEVIANTS_ACTS', response)
      })
  },
  loadDocumentTypes ({ commit, state }) {
    DelinquentService.fetchAllDocumentTypeList()
      .then(response => {
        if (!response.status) commit('SET_IDENTIFICATION_DOCUMENTS', response)
      })
  },
  loadApprehensionItems ({ commit, state }) {
    DelinquentService.fetchAllItemsList()
      .then(response => {
        if (!response.status) commit('SET_APPREHENSION_ITEMS', response)
      })
  },
  switchToClass ({ commit, state }, item) {
    DelinquentService.fetchTrainingClassById(item.id, 'numberOfTrainees,trainees')
      .then(response => {
        if (response.id) {
          response.enrolments.forEach(element => {
            element.trainee.intake = response.intake
            element.trainee.names = element.trainee.firstName + ' ' + element.trainee.lastName
          })
          commit('SET_TRAINING_CLASS', response)
          router.push('/rehabilitation/training-class')
        } else {
          commit('SET_SNACKBAR', true)
          commit('SET_COLOR', 'red')
          commit('SET_TEXT', response.message)
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import Address from './Address'
import Apprehension from './Apprehension'
import Screening from './Screening'
import Transfer from './Transfer'

class Delinquent {
  constructor () {
    this.id = null
    this.firstName = null
    this.lastName = null
    this.nid = null
    this.fatherName = null
    this.motherName = null
    this.gender = null
    this.maritalStatus = null
    this.guardian = null
    this.guardianPhone = null
    this.guardianEmail = null
    this.picture = null
    this.fingerprint = null
    this.dateOfBirth = null
    this.registrationDate = null
    this.phone = null
    this.passportNumber = null
    this.spouseName = null
    this.educationLevel = null
    this.homeless = null
    this.specificDisease = null
    this.fatherVitalStatus = null
    this.motherVitalStatus = null
    this.placeOfBirth = new Address()
    this.apprehension = new Apprehension()
    this.latestScreening = new Screening()
    this.latestTransfer = new Transfer()
    this.latestMedicalTestResults = null
  }

  toString () {
    return `Delinquent{id=${this.id}, firstName='${this.firstName}',lastName='${this.lastName}',
    nid='${this.nid}', fathername='${this.fathername}', mothername='${this.mothername}', gender='${this.gender}',
    martalStatus='${this.martalStatus}', guardian='${this.guardian}', guardianPhone=${this.guardianPhone},
    guardianEmail='${this.guardianEmail}', picture='${this.picture}', fingerPrint='${this.fingerPrint}',
    registrationDate='${this.registrationDate}', phone='${this.phone}', passportNumber='${this.passportNumber}',
    spouseName='${this.spouseName}', educationLevel='${this.educationLevel}', homeless='${this.homeless}',
    specificDisease='${this.specificDisease}', fatherVitalStatus='${this.fatherVitalStatus}',
    motherVitalStatus='${this.motherVitalStatus}', dateOfBirth='${this.dateOfBirth}', registrationDate='${this.registrationDate}',
    placeOfBirth='${this.placeOfBirth}', apprehension='${this.apprehension}', latestScreening='${this.latestScreening}',
    latestTransfer='${this.latestTransfer}', latestMedicalTestResults='${this.latestMedicalTestResults}', }`
  }
}

export default Delinquent


export const state = () => ({
  delinquents: []
})

export const getters = {
  delinquents: (state) => state.delinquents
}

export const mutations = {
  readDelinquents: (state, payload) => {
    state.delinquents = payload
  }
}

export const actions = {
  readDelinquents: ({ commit }, payload) => {
    commit('readDelinquents', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

class Privilege {
  constructor () {
    this.id = 0
    this.name = null
    this.entities = []
  }

  toString () {
    return `Privilege{id=${this.id}, name='${this.name}', entities=${this.entities}}`
  }
}

export default Privilege


class transfer {
  constructor () {
    this.id = null
    this.eligibilityState = null
    this.eligibilityComment = null
    this.receiver = null
    this.arrivalDate = null
    this.recordDate = null
    this.state = null
  }

  toString () {
    return `transfer{id=${this.id}, eligibilityState='${this.eligibilityState}', eligibilityComment='${this.eligibilityComment}', receiver='${this.receiver}',
    arrivalDate='${this.arrivalDate}', recordDate='${this.recordDate}', state='${this.state}'}`
  }
}

export default transfer
